import React, { useMemo, useCallback } from "react";

import { Box, IconButton, Tooltip } from "@chakra-ui/react";
import Link from "@/components/common/Link";
import { Plus } from "react-feather";
import SelectAdvanced from "@/components/common/SelectAdvanced";
import { useTranslation } from "next-i18next";
import useSWR, { mutate } from "swr";
import { updateProfile } from "@/components/withAuthentication";
import { useSettings } from "@/components/SettingsContext";
import { useUser, mutateSession } from "@/hooks/useUser";

const SelectEvent = (): React.ReactElement => {
  const [session] = useUser();
  const { t } = useTranslation("common");
  const { patchSettings } = useSettings();
  const { data: events, mutate: mutateEvents } = useSWR("/event/office");

  const currentOffice = session?.user?.currentOffice;
  React.useEffect(() => {
    mutateEvents();
  }, [currentOffice?.id]);

  const eventOptions = useMemo(
    () =>
      events?.reduce(
        (acc, event) =>
          acc[event?.type] && {
            ...acc,
            [event?.type]: [
              ...acc[event?.type],
              { label: event?.name, value: event?.id },
            ],
          },
        { event: [], conference: [] }
      ),
    [events]
  );

  const options = useMemo(
    () => [
      {
        label: t("events.events"),
        options: eventOptions?.event || [],
      },
      {
        label: t("events.conferences"),
        options: eventOptions?.conference || [],
      },
    ],
    [eventOptions]
  );

  const selectEvent = useCallback(
    async (event) => {
      patchSettings({ isLoading: true });

      await updateProfile({ event: event?.value ?? null });
      await mutateSession();

      await Promise.all(
        events.map((event) => mutate(`/accreditation/event/${event.id}`))
      );

      patchSettings({ isLoading: false });
    },
    [events]
  );

  return (
    <>
      <Box w={{ base: "calc(100vw - 142px)", md: "300px" }}>
        <SelectAdvanced
          options={options}
          onChange={selectEvent}
          value={
            eventOptions?.event?.find(
              (option) => option?.value === session?.user?.profile?.eventId
            ) || null
          }
          placeholder={t("events.chooseEvent")}
        />
      </Box>
      <Tooltip hasArrow label={t("events.addEvent")} placement="bottom">
        <Link to={"/events/add"}>
          <IconButton
            as="a"
            variant="ghost"
            aria-label={t("events.addEvent")}
            icon={<Plus />}
            ml=".5rem"
            size="sm"
            px=".25rem"
            cursor="pointer"
            d={{ base: "none", md: "inline-flex" }}
          />
        </Link>
      </Tooltip>
    </>
  );
};

export default SelectEvent;
