import {
  Tag,
  Box,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { useRouter } from "next/router";

import Avatar from "@/components/common/Avatar";
import { useSettings } from "../SettingsContext";
import { updateProfile } from "../withAuthentication";
import { useSWRConfig } from "swr";

type Role = {
  id?: number;
  type?: string;
  name: string;
  role: string;
  picture: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  otherRoles: Role[];
};

const ChangeRoleModal = ({
  isOpen,
  onClose,
  otherRoles = [],
}: Props): React.ReactElement => {
  const { patchSettings } = useSettings();
  const { t } = useTranslation("common");
  const { mutate } = useSWRConfig();
  const router = useRouter();

  const handleChangeRole = useCallback(async (id, type, role = null) => {
    const profile = {
      event: null,
      role: type === "user" ? role : null,
      pressOffice: type === "press" ? id : null,
      editorialOffice: type === "editorial" ? id : null,
      organization: type === "organization" ? id : null,
    };

    patchSettings({ isPageLoading: true });
    onClose();

    try {
      await updateProfile(profile);
      await router.push("/");

      // Clear cache
      await mutate(() => true, undefined);
    } catch (error) {
      console.error(error);
    } finally {
      patchSettings({ isPageLoading: false });
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent
        onClick={onClose}
        pos="initial"
        maxW="none"
        alignSelf="center"
        shadow="none"
        bg="transparent"
        my="0px"
        px="20px"
        py="10px"
        maxH="100%"
        width="auto"
        overflow="auto"
      >
        <ModalCloseButton
          style={{
            color: "#FFF",
            fontSize: "16px",
            transition: "transform .35s",
          }}
          _hover={{ transform: "rotate(90deg)" }}
        />
        <ModalBody
          d="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          p={0}
          overflow="visible"
        >
          {otherRoles.map(({ id, type, name, role, picture }) => (
            <RoleCard
              key={`${role}-${name}`}
              onClick={() => handleChangeRole(id, type, role)}
            >
              <Avatar src={picture} name={name} size="lg" mb="5" />
              <Tag
                size="sm"
                mb="2"
                variant={
                  type === "press"
                    ? "blue"
                    : type === "editorial"
                    ? "green"
                    : type === "organization"
                    ? "red"
                    : "gray"
                }
              >
                {type === "press" && t("pressOffice.pressOffice")}
                {type === "editorial" && t("editorialOffice.editorialOffice")}
                {type === "organization" && t("organization.organization")}
                {type === "user" && t("user")}
              </Tag>
              <Text
                isTruncated
                w="100%"
                align="center"
                fontSize="1.125rem"
                fontWeight="500"
                mb=".5"
              >
                {name}
              </Text>
              <Text fontSize="0.8rem" isTruncated align="center">
                {role === "user" ? t("reporter") : t(role)}
              </Text>
            </RoleCard>
          ))}
          <RoleCard
            onClick={() => {
              onClose();
              router.push("/register");
            }}
          >
            <Avatar name="+" bg="white" color="black" size="lg" mb="5" />
            <Text align="center">{t("users.addNewEditorialPressOffice")}</Text>
          </RoleCard>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AnimatedBox = animated(Box);

type RoleCardProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const RoleCard = ({ children, onClick }: RoleCardProps) => {
  const ref = useRef(null);
  const [isHovered, setHovered] = useState(false);
  const [animatedProps, setAnimatedProps] = useSpring(() => {
    return {
      xys: [0, 0, 1],
      config: { mass: 10, tension: 400, friction: 40, precision: 0.00001 },
    };
  });

  return (
    <AnimatedBox
      ref={ref}
      onClick={onClick}
      d="inline-flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      padding="2rem"
      bg="rgba(255, 255, 255, 0.2)"
      backdropFilter="blur(5px)"
      borderRadius=".375rem"
      border="1px solid rgba(255, 255, 255, 0.3)"
      backgroundClip="border-box"
      cursor="pointer"
      color="white"
      w="220px"
      height="220px"
      m="10px"
      boxShadow="0 3px 5px rgb(0,0,0,0.03)"
      onMouseEnter={() => setHovered(true)}
      onMouseMove={({ clientX, clientY }) => {
        // Get mouse x position within card
        const x =
          clientX -
          (ref.current.offsetLeft -
            (window.scrollX || window.pageXOffset || document.body.scrollLeft));

        // Get mouse y position within card
        const y =
          clientY -
          (ref.current.offsetTop -
            (window.scrollY || window.pageYOffset || document.body.scrollTop));

        // Set animated values based on mouse position and card dimensions
        const dampen = 10; // Lower the number the less rotation
        const xys = [
          -(y - ref.current.clientHeight / 2) / dampen, // rotateX
          (x - ref.current.clientWidth / 2) / dampen, // rotateY
          1.07, // Scale
        ];

        // Update values to animate to
        setAnimatedProps({ xys: xys });
      }}
      onMouseLeave={() => {
        setHovered(false);
        // Set xys back to original
        setAnimatedProps({ xys: [0, 0, 1] });
      }}
      style={{
        // If hovered we want it to overlap other cards when it scales up
        zIndex: isHovered ? 2 : 1,
        // Interpolate function to handle css changes
        transform: animatedProps.xys.to(
          (x, y, s) =>
            `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
        ),
      }}
    >
      {children}
    </AnimatedBox>
  );
};

export default ChangeRoleModal;
