import {
  Box,
  Flex,
  Text,
  useDisclosure,
  Link as StyledLink,
} from "@chakra-ui/react";
import Head from "next/head";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "react-feather";
import { useSettings } from "../SettingsContext";
import ChangeRoleModal from "./ChangeRoleModal";
import Fade from "react-reveal/Fade";
import getConfig from "next/config";
import router from "next/router";
import { useTranslation } from "react-i18next";

import AnimatedLoader from "@/components/common/AnimatedLoader";
import { OfficeType, OfficeUserRole } from "@/types/office";
import Message from "@/components/common/AlertMessage";
import { Image } from "@/components/common/Image";
import { useUser } from "@/hooks/useUser";

import TermsModal from "../Users/TermsModal";
import Header from "./Header";
import Sidebar from "./Sidebar";

type BreadcrumbItem = {
  label: string;
  isCurrentPage?: boolean;
  icon?: Icon;
  href?: string;
};

type LayoutProps = {
  title: string;
  breadcrumb?: BreadcrumbItem[];
  children: React.ReactNode;
};

const Layout = ({
  title,
  breadcrumb = null,
  children,
}: LayoutProps): React.ReactElement => {
  const timer = useRef();
  const [session] = useUser();
  const { t } = useTranslation();
  const {
    settings: { isLoading, isPageLoading },
  } = useSettings();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showSpinner, setShowSpinner] = useState(false);

  const unactive = React.useMemo(() => {
    if (isPageLoading || !session?.currentRole) return false;
    return (
      !session.currentRole.subscribed &&
      (session.currentRole.role === "admin" ||
        session.currentRole.role === "mod") &&
      session.currentRole.type !== OfficeType.EDITORIAL
    );
  }, [session, isPageLoading]);

  const currentRole = session?.currentRole;
  const otherRoles = useMemo(
    () =>
      session?.allRoles?.filter(
        ({ id, type, role }) =>
          !(
            currentRole?.id === id &&
            currentRole?.type === type &&
            currentRole?.role === role
          ) && role !== OfficeUserRole.REPORTER
      ),
    [session]
  );
  React.useEffect(() => {
    if (currentRole === null) onOpen();
  }, [currentRole]);

  const MemoizedLoader = useMemo(() => <AnimatedLoader />, []);

  useEffect(() => {
    if (isLoading) timer.current = setTimeout(() => setShowSpinner(true), 500);
    else if (isPageLoading) setShowSpinner(true);
    else setShowSpinner(false);

    return () => clearTimeout(timer.current);
  }, [isLoading, isPageLoading, session]);

  const { publicRuntimeConfig } = getConfig();

  if (!session?.user?.id) {
    return (
      <>
        <Head>
          <title>{title} - Accred.eu</title>
        </Head>
        <Flex
          position="fixed"
          justifyContent="center"
          alignItems="center"
          inset={0}
        >
          {MemoizedLoader}
        </Flex>
        {children}
      </>
    );
  }

  if (session?.user?.acceptedRegulations === false) {
    return (
      <>
        <Head>
          <title>{title} - Accred.eu</title>
        </Head>
        <Flex
          position="fixed"
          justifyContent="center"
          alignItems="center"
          inset={0}
        >
          <TermsModal />
        </Flex>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>{title} - Accred.eu</title>
        <script src="https://platform.twitter.com/widgets.js"></script>
      </Head>
      {unactive && (
        <Box
          _hover={{ transform: "scale(1.02,1.05)" }}
          mt="1.25rem"
          mx={{ base: ".75rem", md: "2rem" }}
          onClick={() => router.push("/payments")}
          transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
          transitionDuration=".3s"
          transitionProperty="transform,box-shadow"
          cursor="pointer"
        >
          <Message
            justifyContent="center"
            mb={0}
            status="error"
            message={t("payments.subscription.noSubscriptionLayout")}
          />
        </Box>
      )}
      <ChangeRoleModal
        isOpen={isOpen}
        onClose={onClose}
        otherRoles={otherRoles}
      />
      {showSpinner && (
        <Flex
          position="fixed"
          justifyContent="center"
          alignItems="center"
          inset={0}
          zIndex={99999999999999999999}
          backdropFilter="auto"
          backdropBlur="5px"
          // backgroundColor="rgba(255,255,255,.8)"
          // backgroundColor="#1c3faacc"
          // pointerEvents="none"
        >
          <Fade
            duration={350}
            when={showSpinner && (isLoading || isPageLoading)}
          >
            {MemoizedLoader}
          </Fade>
        </Flex>
      )}
      <Flex
        py="1.25rem"
        px={{ base: ".75rem", md: "2rem" }}
        direction={{ base: "column", md: "row" }}
      >
        <Sidebar openChangeRole={onOpen} />
        <Box flex="1 1 0%">
          <Box
            borderRadius="30px"
            p={{ base: "0 1rem 1rem", md: "0 22px 2.5rem" }}
            minHeight="calc(100vh - 2.5rem)"
            bg="light.100"
          >
            <Header breadcrumb={breadcrumb} openChangeRole={onOpen} />
            {children}
          </Box>
          <Flex
            mt={3}
            justify={{ base: "center", md: "flex-end" }}
            textAlign={{ base: "center", md: "right" }}
            wrap={{ base: "wrap", md: "nowrap" }}
          >
            <StyledLink
              color="light.10"
              opacity={0.7}
              _hover={{ opacity: 1 }}
              isExternal
              href={`${publicRuntimeConfig.basePath}/polityka_prywatnosci.pdf`}
            >
              {t("users.privacyPolicy")}
            </StyledLink>
            <Text color="bg.600" mx={2}>
              |
            </Text>
            <StyledLink
              color="light.10"
              opacity={0.7}
              _hover={{ opacity: 1 }}
              isExternal
              href={`${publicRuntimeConfig.basePath}/regulamin.pdf`}
            >
              {t("users.TOS")}
            </StyledLink>
            <Text color="bg.600" mx={2} display={{ base: "none", md: "block" }}>
              |
            </Text>
            <Flex
              align="center"
              justify="flex-end"
              mt={{ base: "10px", md: "0" }}
            >
              <Text color="light.10" mr={2} fontSize="xs">
                {t("poweredBy")}
              </Text>
              <StyledLink href="https://accred.eu" isExternal>
                <Box width="80px" height="14px" position="relative">
                  <Image
                    priority
                    src="/logo-accred.svg"
                    objectFit="contain"
                    objectPosition="left"
                    alt=""
                  />
                </Box>
              </StyledLink>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Layout;
