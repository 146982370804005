import React from "react";

import {
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Box,
  IconButton,
} from "@chakra-ui/react";
import Link from "next/link";
import {
  ChevronRight,
  ChevronLeft,
  Sidebar,
  Clock,
  Icon as IconType,
} from "react-feather";
import LanguageSwitcher from "./LanguageSwitcher";
import { useSettings } from "@/components/SettingsContext";
import { useTranslation } from "next-i18next";
import SelectEvent from "./SelectEvent";
import Notifications from "./Notifications";
import { OfficeType } from "@/types/office";
import UserMenu from "./UserMenu";
import { useUser } from "@/hooks/useUser";

import Fade from "react-reveal/Fade";

const ChevronRightIcon = (props) => <Icon as={ChevronRight} {...props} />;

const HamburgerMenu = ({
  collapsed,
  onClick,
  ...props
}: {
  collapsed: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation("common");

  return (
    <Box position="relative" d={{ base: "none", md: "block" }}>
      <Icon
        as={ChevronLeft}
        color="text.800"
        w="0.75rem"
        h="0.75rem"
        position="absolute"
        left="0"
        top="52%"
        transform={`translate(${collapsed ? "0.25rem" : "0.5rem"},-50%)`}
        opacity={collapsed ? 0 : 100}
        transition="transform .5s, opacity .5s"
      />
      <Icon
        as={ChevronRight}
        color="text.800"
        w="0.75rem"
        h="0.75rem"
        position="absolute"
        left="0"
        top="52%"
        transform={`translate(${collapsed ? "0.5rem" : "0.85rem"},-50%)`}
        opacity={collapsed ? 100 : 0}
        transition="transform .5s, opacity .5s"
      />
      <IconButton
        as={Sidebar}
        aria-label={t("users.sidebar")}
        variant="unstyled"
        size="xs"
        color="text.800"
        onClick={onClick}
        {...props}
      />
    </Box>
  );
};

const Header = ({
  breadcrumb,
  openChangeRole,
}: {
  breadcrumb: [
    { label: string; isCurrentPage?: boolean; icon?: IconType; href?: string }
  ];
  openChangeRole: () => void;
}): React.ReactElement => {
  const [session] = useUser();
  const { settings, patchSettings } = useSettings();

  return (
    <Flex
      align="center"
      justify="space-between"
      h="67px"
      position="relative"
      borderBottomWidth="1px"
      borderColor="light.200"
      mb="2rem"
      zIndex={999}
    >
      <Flex
        display="flex"
        alignItems="center"
        position="relative"
        cursor="pointer"
      >
        <Fade left duration={1000}>
          <HamburgerMenu
            collapsed={settings.menuCollapsed}
            onClick={() =>
              patchSettings({ menuCollapsed: !settings.menuCollapsed })
            }
            mr={2}
          />
        </Fade>
        {breadcrumb && (
          <Fade left duration={1000}>
            <Breadcrumb
              spacing=".25rem"
              separator={
                <ChevronRightIcon color="text.700" w="14px" h="14px" />
              }
              d={{ base: "none", md: "block" }}
            >
              {breadcrumb.map((item, index) => (
                <BreadcrumbItem
                  isCurrentPage={item.isCurrentPage}
                  key={`breadcrumb-${index}`}
                >
                  {item.href ? (
                    <Link href={item.href}>
                      <BreadcrumbLink color="text.900">
                        {item.label}
                      </BreadcrumbLink>
                    </Link>
                  ) : (
                    <BreadcrumbLink isCurrentPage color="text.900">
                      {item.label}
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </Fade>
        )}
      </Flex>
      <Flex align="center" h="40px">
        {session?.currentRole?.type === OfficeType.PRESS && (
          <Fade bottom duration={1000}>
            <Flex mr={{ base: ".75rem", md: "1.5rem" }} align="center">
              <SelectEvent />
            </Flex>
          </Fade>
        )}
        {/* <Fade right duration={1000}>
          <Flex mr="1.5rem">
            <Icon
              as={Clock}
              color="text.800"
              w="1.25rem"
              h="1.25rem"
              mr=".5rem"
            />
            <Text>CEST</Text>
          </Flex>
        </Fade>
        <Fade right duration={1000}>
          <Notifications />
        </Fade> // TODO Show when this functions wiil be ready */}
        <Fade right duration={1000}>
          <LanguageSwitcher isPanel />
        </Fade>
        <Fade right duration={1000}>
          <UserMenu openChangeRole={openChangeRole} />
        </Fade>
      </Flex>
    </Flex>
  );
};

export default Header;
