/* eslint-disable react/display-name */
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";

import Avatar from "@/components/common/Avatar";
import { ImageAutoWidth } from "@/components/common/Image";
import { HelpChatLink } from "@/consts/help";
import { useUser } from "@/hooks/useUser";
import { OfficeType, OfficeUserRole } from "@/types/office";
import { UserRole } from "@/types/user";
import {
  Box,
  Link as ChakraLink,
  Collapse,
  Divider,
  Flex,
  Icon,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  Activity,
  BarChart2,
  Briefcase,
  Calendar,
  CheckSquare,
  ChevronDown,
  ChevronUp,
  CreditCard,
  DollarSign,
  Edit,
  Edit2,
  Edit3,
  File as FileIcon,
  FileText,
  Globe,
  Grid,
  HelpCircle,
  Home,
  Icon as IconType,
  Mail,
  PenTool,
  Repeat,
  Settings,
  Sliders,
  Square,
  Tablet,
  UploadCloud,
  User,
  Users,
} from "react-feather";
import { useSettings } from "../SettingsContext";

import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import useSWR from "swr";

const MAX_DISPLAYED_ROLES = 3;

type NavItemProp = {
  ico?: IconType;
  label: string;
  href?: string;
  active?: boolean;
  collapsed?: boolean;
  disabled?: boolean;
  subItems?: NavSubItem[];
  onClick?: () => void;
  onSubItemClick?: () => void;
};

const NavSubItem = React.forwardRef(
  (
    {
      ico,
      href,
      label,
      onClick,
      active = false,
      collapsed = false,
    }: NavItemProp,
    ref
  ) => (
    <Flex
      width="100%"
      as="a"
      h="50px"
      position="relative"
      mb=".25rem"
      align="center"
      pl="1.25rem"
      color={active ? "text.900" : "light.10"}
      cursor="pointer"
      fontWeight={active ? 500 : "normal"}
      role="group"
      ref={ref}
      href={href ? href : undefined}
      onClick={onClick}
      overflow="hidden"
    >
      <Tooltip
        hasArrow
        label={label}
        placement="right"
        ml="20px"
        isDisabled={collapsed ? false : true}
      >
        <Fade right duration={1000}>
          <Box
            zIndex="-1"
            position="absolute"
            top={0}
            right={active ? 0 : "auto"}
            left={active ? "auto" : 0}
            mr={active ? "-1.25rem" : 0}
            h="100%"
            w={active ? "3rem" : "230px"}
            borderTopLeftRadius={active ? 0 : "9999px"}
            borderBottomLeftRadius={active ? 0 : "9999px"}
            transitionProperty="background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter"
            transitionDuration=".1s"
            transitionTimingFunction="cubic-bezier(.4,0,1,1)"
          ></Box>
          {ico == Tablet ? (
            <Box pos="relative" mr=".75rem">
              <Icon
                as={User}
                color={active ? "light.10" : "text.100"}
                w="10px"
                h="10px"
                _groupHover={{
                  color: "light.10",
                }}
                position="absolute"
                bottom="5px"
                left="calc(50% - 5px)"
              />
              <Icon
                as={ico}
                color={active ? "light.10" : "text.100"}
                w="24px"
                h="24px"
                _groupHover={{
                  color: "light.10",
                }}
                transform="rotate(180deg)"
              />
            </Box>
          ) : (
            <Icon
              as={ico}
              color={active ? "light.10" : "text.100"}
              w="24px"
              h="24px"
              mr=".75rem"
              _groupHover={{
                color: "light.10",
              }}
            />
          )}
          <Text
            isTruncated
            transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
            transitionDuration=".3s"
            transitionProperty="opacity,color"
            opacity={{ base: 1, md: collapsed ? 0 : 1 }}
            color={active ? "light.10" : "text.100"}
            _groupHover={{
              color: "light.10",
            }}
          >
            {label}
          </Text>
        </Fade>
      </Tooltip>
    </Flex>
  )
);

const NavItem = React.forwardRef(
  (
    {
      ico,
      label,
      href,
      onClick,
      onSubItemClick,
      subItems,
      active = false,
      collapsed = false,
      disabled = false,
    }: NavItemProp,
    ref
  ) => {
    const submenu = useDisclosure({ defaultIsOpen: active });

    return (
      <div
        style={
          disabled
            ? {
                opacity: 0.5,
                pointerEvents: "none",
                textDecoration: "none",
              }
            : null
        }
      >
        <Box w="100%">
          <Flex
            width="100%"
            as="a"
            h="50px"
            position="relative"
            mb=".25rem"
            align="center"
            borderRadius="9999px"
            pl="1.25rem"
            color={active ? "text.900" : "light.10"}
            cursor="pointer"
            bg={active ? "light.100" : "transparent"}
            fontWeight={active ? 500 : "normal"}
            role="group"
            ref={ref}
            href={!subItems ? href : undefined}
            onClick={subItems ? submenu.onToggle : onClick}
          >
            <Box
              opacity={active ? 1 : 0}
              content='""'
              w="30px"
              h="30px"
              position="absolute"
              top={0}
              right={0}
              mr="-1.25rem"
              mt="-30px"
              transform="rotate(90deg) scale(1.04)"
              color="light.100"
              d={{ base: "none", md: "block" }}
            >
              <svg viewBox="0 0 259.51 259.52">
                <path
                  d="M259.51 259.52c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828a237.651 237.651 0 00-7.292-8.589c-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3A115.051 115.051 0 000 .081h259.51z"
                  fill="currentColor"
                />
              </svg>
            </Box>
            <Tooltip
              hasArrow
              label={label}
              placement="right"
              ml="20px"
              isDisabled={collapsed ? false : true}
            >
              <>
                <Box
                  zIndex="-1"
                  position="absolute"
                  top={0}
                  right={active ? 0 : "auto"}
                  left={active ? "auto" : 0}
                  mr={active ? "-1.25rem" : 0}
                  h="100%"
                  w={active ? "3rem" : "230px"}
                  bg={active ? "light.100" : "transparent"}
                  borderTopLeftRadius={active ? 0 : "9999px"}
                  borderBottomLeftRadius={active ? 0 : "9999px"}
                  transitionProperty="background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter"
                  transitionDuration=".1s"
                  transitionTimingFunction="cubic-bezier(.4,0,1,1)"
                  _groupHover={{
                    bg: active
                      ? "light.100"
                      : collapsed || (submenu.isOpen && subItems?.length > 0)
                      ? "transparent"
                      : "main.600",
                  }}
                  d={{ base: "none", md: "block" }}
                ></Box>

                <Fade left duration={1500}>
                  <Icon
                    as={ico}
                    color={active ? "main.600" : "light.10"}
                    w="24px"
                    h="24px"
                    mr=".75rem"
                  />
                </Fade>
                <Fade left>
                  <Text
                    isTruncated
                    transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                    transitionDuration=".3s"
                    transitionProperty="opacity"
                    opacity={{ base: 1, md: collapsed ? 0 : 1 }}
                  >
                    {label}
                  </Text>
                </Fade>
                <Fade left>
                  {subItems && (
                    <Icon
                      as={submenu.isOpen ? ChevronUp : ChevronDown}
                      w="16px"
                      h="16px"
                      ml="auto"
                      mr="1.25rem"
                    />
                  )}
                </Fade>
              </>
            </Tooltip>
            <Box
              opacity={active ? 1 : 0}
              content='""'
              w="30px"
              h="30px"
              position="absolute"
              top={0}
              right={0}
              mr="-1.25rem"
              mt="50px"
              transform="scale(1.04)"
              color="light.100"
              d={{ base: "none", md: "block" }}
            >
              <svg viewBox="0 0 259.51 259.52">
                <path
                  d="M259.51 259.52c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828a237.651 237.651 0 00-7.292-8.589c-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3A115.051 115.051 0 000 .081h259.51z"
                  fill="currentColor"
                />
              </svg>
            </Box>
          </Flex>

          {subItems && (
            <Collapse in={submenu.isOpen}>
              <Box borderRadius=".375rem" bg="main.900">
                {subItems?.map((subItem) => (
                  <Link passHref href={subItem.href} key={subItem.label}>
                    <NavSubItem
                      ico={subItem.icon}
                      label={subItem.label}
                      collapsed={collapsed}
                      onClick={onSubItemClick}
                    />
                  </Link>
                ))}
              </Box>
            </Collapse>
          )}
        </Box>
      </div>
    );
  }
);

type NavSubItem = {
  icon: IconType;
  label: string;
  href: string;
};

type ValidType = "user" | OfficeType;
type ValidRole = UserRole | OfficeUserRole;

type NavItem = {
  icon: IconType;
  label: string;
  href?: string;
  type?: ValidType | ValidType[];
  role?: ValidRole | ValidRole[];
  subItems?: NavSubItem[];
  isHidden?: boolean;
  disabled?: boolean;
  isExternal?: true;
};

type Props = {
  openChangeRole: () => void;
};

const Sidebar = ({ openChangeRole }: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const { settings } = useSettings();
  const router = useRouter();
  const [session] = useUser();
  const { pathname } = router;
  const eventId = session?.user?.profile?.eventId;
  const showMoreRoles = session?.allRoles?.length > MAX_DISPLAYED_ROLES;
  const currentRole = session?.currentRole;

  const otherRoles = useMemo(
    () =>
      session?.allRoles
        ?.filter(
          ({ id, type, role }) =>
            !(
              currentRole?.id === id &&
              currentRole?.type === type &&
              currentRole?.role === role
            ) && role !== OfficeUserRole.REPORTER
        )
        ?.slice(0, 2),
    [session]
  );

  const unactive = React.useMemo(() => {
    if (!session?.currentRole) return false;
    return (
      !session.currentRole.subscribed &&
      (session.currentRole.role === "admin" ||
        session.currentRole.role === "mod") &&
      (session.currentRole.type === OfficeType.ORGANIZATION ||
        session.currentRole.type === OfficeType.PRESS)
    );
  }, [session]);

  const allNavItems: NavItem[] = useMemo(
    () => [
      {
        icon: Home,
        label: t("users.dashboard"),
        href: "/",
        disabled: unactive,
      },
      {
        icon: Activity,
        label: t("press.news"),
        href: "/news",
        disabled: unactive,
        type: ["user", OfficeType.ORGANIZATION],
        role: [
          UserRole.SUPERADMIN,
          UserRole.USER,
          OfficeUserRole.ADMIN,
          OfficeUserRole.MOD,
        ],
      },
      {
        disabled: unactive,
        icon: FileText,
        label: t("pressOffice.pressOffices"),
        href: "/offices",
        type: ["user", OfficeType.ORGANIZATION],
        role: [
          UserRole.SUPERADMIN,
          UserRole.USER,
          OfficeUserRole.ADMIN,
          OfficeUserRole.MOD,
        ],
      },
      {
        icon: Edit2,
        label: t("editorialOffice.editorialOffices"),
        href: "/editors",
        type: "user",
        role: [UserRole.SUPERADMIN, UserRole.USER],
      },
      {
        icon: Briefcase,
        label: t("organization.organizations"),
        href: "/organizations",
        type: "user",
        role: UserRole.SUPERADMIN,
      },
      {
        icon: Mail,
        label: t("office.invitations"),
        href: "/invitations",
        type: "user",
        role: UserRole.USER,
      },
      {
        icon: CheckSquare,
        label: t("events.accreditation"),
        href: "/accreditation",
        type: "user",
        role: UserRole.USER,
      },
      {
        icon: PenTool,
        label: t("press.pressInfo"),
        href: "/press",
        disabled: unactive,
        type: [OfficeType.PRESS],
      },
      {
        icon: Mail,
        label: t("newsletter.newsletter"),
        href: "/newsletter",
        disabled: unactive,
        type: [OfficeType.PRESS],
      },
      {
        icon: Users,
        label: t("office.users"),
        href: "/users",
        disabled: unactive,
        type: [
          "user",
          OfficeType.PRESS,
          OfficeType.EDITORIAL,
          OfficeType.ORGANIZATION,
        ],
        role: [UserRole.SUPERADMIN, OfficeUserRole.ADMIN, OfficeUserRole.MOD],
      },
      {
        icon: Repeat,
        label: t("superAdmin.api"),
        href: "/api-settings",
        type: "user",
        role: UserRole.SUPERADMIN,
      },
      {
        icon: Settings,
        label: t("settings.settings"),
        href: "/settings",
        type: "user",
        role: UserRole.SUPERADMIN,
      },
      {
        isHidden: !!eventId,
        icon: Grid,
        label: t("events.events"),
        href: "/events",
        disabled: unactive,
        type: [OfficeType.PRESS],
      },
      {
        isHidden: !eventId,
        icon: Square,
        label: t("events.event"),
        href: "/events",
        disabled: unactive,
        type: [OfficeType.PRESS],
        subItems: [
          {
            icon: Grid,
            label: t("events.list"),
            href: "/events",
            disabled: unactive,
          },
          {
            icon: Sliders,
            label: t("edit"),
            href: "/events/edit",
            disabled: unactive,
          },
          {
            icon: Edit3,
            label: t("events.application"),
            href: "/events/application",
            disabled: unactive,
          },
          {
            icon: Edit2,
            label: t("events.accreditationApplications"),
            href: "/events/applications",
            disabled: unactive,
          },
          {
            icon: Edit,
            label: t("events.form"),
            href: `/accreditation/event/${session?.user?.profile?.eventId}`,
            disabled: unactive,
          },
          // { icon: Tablet, label: t("settings.badges"), href: "#" },
        ],
      },
      {
        icon: UploadCloud,
        label: t("cloud.cloud"),
        href: "/cloud",
        disabled: unactive,
        type: [OfficeType.PRESS],
      },
      {
        icon: Calendar,
        label: t("calendar.calendar"),
        href: "/calendar",
        disabled: unactive,
        type: [
          "user",
          OfficeType.PRESS,
          OfficeType.EDITORIAL,
          OfficeType.ORGANIZATION,
        ],
        role: [
          UserRole.USER,
          OfficeUserRole.ADMIN,
          OfficeUserRole.MOD,
          OfficeUserRole.REPORTER,
        ],
      },
      {
        icon: Globe,
        label: t("website.pressWebsite"),
        href: "/website",
        type: [OfficeType.PRESS, OfficeType.ORGANIZATION],
        isExternal: false,
        disabled: unactive,
      },
      {
        icon: DollarSign,
        label: t("payments.payments"),
        href: "/payments",
        type: [OfficeType.PRESS, OfficeType.ORGANIZATION],
      },
      {
        icon: DollarSign,
        label: t("payments.admin.subscriptions"),
        href: "/payments",
        type: "user",
        role: UserRole.SUPERADMIN,
      },
      {
        icon: FileIcon,
        label: t("payments.invoices.invoices"),
        href: "/invoice",
        type: "user",
        role: UserRole.SUPERADMIN,
      },
      {
        icon: CreditCard,
        label: t("payments.transactions"),
        href: "/transactions",
        type: "user",
        role: UserRole.SUPERADMIN,
      },
      {
        icon: HelpCircle,
        label: t("help.help"),
        href: HelpChatLink,
        isExternal: true,
        type: [
          "user",
          OfficeType.PRESS,
          OfficeType.EDITORIAL,
          OfficeType.ORGANIZATION,
        ],
        role: [
          UserRole.USER,
          OfficeUserRole.ADMIN,
          OfficeUserRole.MOD,
          OfficeUserRole.REPORTER,
        ],
      },
      {
        icon: Settings,
        label: t("settings.settings"),
        href: "/settings",
        type: [OfficeType.PRESS, OfficeType.EDITORIAL, OfficeType.ORGANIZATION],
      },
    ],
    [eventId, unactive]
  );

  const navItems = useMemo(
    () =>
      allNavItems.filter(
        ({ role, type, isHidden }) =>
          !isHidden &&
          !(role && !role.includes(currentRole?.role)) &&
          !(type && !type.includes(currentRole?.type))
      ),
    [session]
  );

  const isActive = (path: string) =>
    pathname === path || (pathname.startsWith(path) && !path.endsWith("/"));

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Flex
      direction="column"
      as="aside"
      w={{ base: "100%", md: settings.menuCollapsed ? "85px" : "230px" }}
      pr={{ base: 0, md: "1.25rem" }}
      pb={{ base: "1.5rem", md: "4rem" }}
      transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
      transitionDuration=".3s"
      transitionProperty="width"
      mt={{ base: "-1.25rem", md: 0 }}
    >
      <Fade right cascade>
        <Flex
          pl={{ base: 0, md: "1.25rem" }}
          pt={{ base: "22px", md: "1rem" }}
          h="48px"
          justify="flex-start"
        >
          <Box>
            <Flex>
              <Box cursor="pointer" w="26px" h="26px" flexShrink={0}>
                <Link passHref href="/">
                  <ImageAutoWidth
                    priority
                    layout="fill"
                    src="/logo-signet.svg"
                    alt="Accred logo signet"
                  />
                </Link>
              </Box>
              <Box
                cursor="pointer"
                transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                transitionDuration=".3s"
                transitionProperty="width,opacity"
                w="118px"
                h="36px"
                opacity={{ base: 1, md: settings.menuCollapsed ? 0 : 1 }}
              >
                <Link passHref href="/">
                  <ImageAutoWidth
                    priority
                    layout="fill"
                    src="/logo-text.svg"
                    alt="Accred logo text"
                  />
                </Link>
              </Box>
            </Flex>
          </Box>
          <Flex ml="auto">
            <Avatar
              src={currentRole?.picture}
              name={currentRole?.name || ""}
              size="sm"
              transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
              transitionDuration=".3s"
              transitionProperty="all"
              zIndex={2}
              boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
              onClick={openChangeRole}
              d={{ base: "flex", md: "none" }}
              mr={3}
            />
            <Box
              d={{ base: "block", md: "none" }}
              transform="rotate(-90deg)"
              color="light.10"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <Icon as={BarChart2} w="32px" h="32px" />
            </Box>
          </Flex>
        </Flex>
      </Fade>
      <Divider
        mt={{ base: "22px", md: "1.25rem" }}
        mb={{ base: 0, md: "1.5rem" }}
        mx={{ base: "-.75rem", md: 0 }}
        borderColor="bg.600"
        w={{ base: "calc(100% + 1.5rem)", md: "100%" }}
      />
      <Zoom duration={850}>
        <Box
          onClick={openChangeRole}
          cursor="pointer"
          mb="1.5rem"
          d={{ base: "none", md: "block" }}
          textAlign="center"
        >
          <Flex justify="center" mb={4} flexDirection="row-reverse">
            <Tooltip
              hasArrow
              label={t("users.addNewRole")}
              placement="right"
              ml="20px"
              isDisabled={settings.menuCollapsed}
            >
              <Avatar
                name="+"
                bg="white"
                color="black"
                opacity={settings.menuCollapsed ? 0 : 1}
                size="lg"
                marginLeft={settings.menuCollapsed ? "-4rem" : "-2rem"}
                transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                transitionDuration=".3s"
                transitionProperty="all"
                zIndex={1}
                boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
                _hover={{ marginLeft: "-1.5rem", transform: "rotate(10deg)" }}
              />
            </Tooltip>
            {showMoreRoles && (
              <Tooltip
                hasArrow
                label={t("users.moreRoles")}
                placement="right"
                ml="20px"
                isDisabled={settings.menuCollapsed}
              >
                <Avatar
                  name="…"
                  bg="light.200"
                  color="black"
                  opacity={settings.menuCollapsed ? 0 : 1}
                  size="lg"
                  marginLeft={settings.menuCollapsed ? "-4rem" : "-2rem"}
                  transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                  transitionDuration=".3s"
                  transitionProperty="all"
                  zIndex={1}
                  boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
                  _hover={{ marginLeft: "-1.5rem", transform: "rotate(10deg)" }}
                />
              </Tooltip>
            )}
            {otherRoles?.map(({ name, role, picture }, index) => (
              <Tooltip
                key={`role-${index}`}
                hasArrow
                label={`${name} (${t(role)})`}
                placement="right"
                ml="20px"
                isDisabled={settings.menuCollapsed}
              >
                <Avatar
                  src={picture}
                  name={name}
                  opacity={settings.menuCollapsed ? 0 : 1}
                  size="lg"
                  marginLeft={settings.menuCollapsed ? "-4rem" : "-2rem"}
                  transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                  transitionDuration=".3s"
                  transitionProperty="all"
                  zIndex={1}
                  boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
                />
              </Tooltip>
            ))}
            <Tooltip
              hasArrow
              label={
                currentRole
                  ? `${currentRole?.name} (${t(currentRole?.role)})`
                  : ""
              }
              placement="right"
              ml="20px"
              isDisabled={!settings.menuCollapsed}
            >
              <Avatar
                src={currentRole?.picture}
                name={currentRole?.name || ""}
                size="lg"
                transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                transitionDuration=".3s"
                transitionProperty="all"
                zIndex={2}
                boxShadow="0 0 transparent,0 0 transparent,0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
              />
            </Tooltip>
          </Flex>
          <Text
            isTruncated
            align="center"
            color="light.10"
            fontSize="1.125rem"
            fontWeight="500"
            mb={1}
            transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
            transitionDuration=".3s"
            transitionProperty="opacity"
            opacity={settings.menuCollapsed ? 0 : 1}
          >
            {currentRole?.name || ""}
          </Text>
          <Tag
            mt={1}
            size="sm"
            isTruncated
            transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
            transitionDuration=".3s"
            transitionProperty="opacity"
            opacity={settings.menuCollapsed ? 0 : 1}
            variant={
              currentRole?.type === OfficeType.PRESS
                ? "blue"
                : currentRole?.type === OfficeType.EDITORIAL
                ? "green"
                : currentRole?.type === OfficeType.ORGANIZATION
                ? "red"
                : "gray"
            }
          >
            {currentRole?.type === OfficeType.PRESS &&
              t("pressOffice.pressOffice")}
            {currentRole?.type === OfficeType.EDITORIAL &&
              t("editorialOffice.editorialOffice")}
            {currentRole?.type === OfficeType.ORGANIZATION &&
              t("organization.organization")}
            {currentRole?.type === "user" && t("user")}
          </Tag>
        </Box>
      </Zoom>
      <Box
        pt={{ base: menuOpen ? "1.25rem" : 0, md: 0 }}
        maxH={{ base: menuOpen ? "1000px" : 0, md: "none" }}
        overflow={{ base: "hidden", md: "visible" }}
        transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
        transitionDuration=".3s"
        transitionProperty="max-height,padding"
      >
        {navItems?.map((navItem, index) => {
          if (navItem?.isExternal) {
            return (
              <ChakraLink href={navItem.href} key={navItem.href} isExternal>
                <NavItem
                  collapsed={settings.menuCollapsed}
                  ico={navItem.icon}
                  label={navItem.label}
                  subItems={navItem.subItems}
                  active={isActive(navItem.href)}
                  onClick={() => setMenuOpen(false)}
                  disabled={navItem.disabled}
                  onSubItemClick={() => setMenuOpen(false)}
                />
              </ChakraLink>
            );
          } else if (navItem.href === "/settings") {
            return (
              <Box pt={10} key={navItem.href}>
                <Link href={navItem.href} passHref>
                  <NavItem
                    collapsed={settings.menuCollapsed}
                    ico={navItem.icon}
                    label={navItem.label}
                    href={navItem.href}
                    subItems={navItem.subItems}
                    active={isActive(navItem.href)}
                    onClick={() => setMenuOpen(false)}
                    disabled={navItem.disabled}
                    onSubItemClick={() => setMenuOpen(false)}
                  />
                </Link>
              </Box>
            );
          } else {
            return (
              <Link href={navItem.href} key={index} passHref>
                <NavItem
                  collapsed={settings.menuCollapsed}
                  key={index}
                  ico={navItem.icon}
                  label={navItem.label}
                  href={navItem.href}
                  subItems={navItem.subItems}
                  active={isActive(navItem.href)}
                  onClick={() => setMenuOpen(false)}
                  disabled={navItem.disabled}
                  onSubItemClick={() => setMenuOpen(false)}
                />
              </Link>
            );
          }
        })}
      </Box>
    </Flex>
  );
};

export default Sidebar;
