import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useTranslation } from "next-i18next";
import { selectStyles } from "@/utils/selectStyles";

const animatedComponents = makeAnimated();

const SelectAdvanced = React.forwardRef(
  (
    {
      readOnly = false,
      lg = false,
      isInvalid = false,
      tags = false,
      sm = false,
      rounded = true,
      ...rest
    }: {
      readOnly?: boolean;
      lg?: boolean;
      isInvalid?: boolean;
      tags?: boolean;
      sm?: boolean;
      rounded?: boolean;
      [x: string]: unknown;
    },
    ref
  ): React.ReactElement => {
    const { t } = useTranslation("common");

    return (
      <Select
        isClearable
        styles={selectStyles(lg, isInvalid, tags, sm, rounded)}
        placeholder={t("choose")}
        isSearchable={!readOnly}
        menuIsOpen={readOnly ? false : undefined}
        noOptionsMessage={() => t("noOptions")}
        components={animatedComponents}
        ref={ref}
        {...rest}
      />
    );
  }
);

SelectAdvanced.displayName = "SelectAdvanced";

export default SelectAdvanced;
