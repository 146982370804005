export enum UserRole {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  USER = "user",
}

export const userRoleOptions = [
  {
    label: "superadmin",
    value: UserRole.SUPERADMIN,
  },
  {
    label: "user",
    value: UserRole.USER,
  },
];

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  active: boolean;
  blocked: boolean;
  profile: Profile;
  createdAt: Date;
  updatedAt: Date;
};

export type Profile = {
  picture?: string;
  locale?: string;
  menuCollapsed: boolean;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
};

export enum UserInvitationStatuses {
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  WAITING = "waiting",
}
