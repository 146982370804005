import React from "react";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";

const Message = ({ status, message, ...rest }) => {
  return (
    <Alert status={status} mb="20px" {...rest}>
      <AlertIcon />
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};

export default Message;
