import React from "react";
import styled from "@emotion/styled";
import { Box, Flex } from "@chakra-ui/react";
import { ImageAutoHeight } from "./Image";

const color = "#1c3faa";

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background: ${() => color};

  .blob {
    width: 2rem;
    height: 2rem;
    background: rgba(230, 230, 230, 0.85);
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.25);
  }

  .blob-2 {
    -webkit-animation: animate-to-2 1.5s infinite;
    animation: animate-to-2 1.5s infinite;
  }

  .blob-3 {
    -webkit-animation: animate-to-3 1.5s infinite;
    animation: animate-to-3 1.5s infinite;
  }

  .blob-1 {
    -webkit-animation: animate-to-1 1.5s infinite;
    animation: animate-to-1 1.5s infinite;
  }

  .blob-4 {
    -webkit-animation: animate-to-4 1.5s infinite;
    animation: animate-to-4 1.5s infinite;
  }

  .blob-0 {
    -webkit-animation: animate-to-0 1.5s infinite;
    animation: animate-to-0 1.5s infinite;
  }

  .blob-5 {
    -webkit-animation: animate-to-5 1.5s infinite;
    animation: animate-to-5 1.5s infinite;
  }

  @-webkit-keyframes animate-to-2 {
    25%,
    75% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }

  @keyframes animate-to-2 {
    25%,
    75% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @-webkit-keyframes animate-to-3 {
    25%,
    75% {
      transform: translateX(1.5rem) scale(0.75);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @keyframes animate-to-3 {
    25%,
    75% {
      transform: translateX(1.5rem) scale(0.75);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @-webkit-keyframes animate-to-1 {
    25% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    50%,
    75% {
      transform: translateX(-4.5rem) scale(0.6);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @keyframes animate-to-1 {
    25% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    50%,
    75% {
      transform: translateX(-4.5rem) scale(0.6);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @-webkit-keyframes animate-to-4 {
    25% {
      transform: translateX(1.5rem) scale(0.75);
    }
    50%,
    75% {
      transform: translateX(4.5rem) scale(0.6);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @keyframes animate-to-4 {
    25% {
      transform: translateX(1.5rem) scale(0.75);
    }
    50%,
    75% {
      transform: translateX(4.5rem) scale(0.6);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @-webkit-keyframes animate-to-0 {
    25% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    50% {
      transform: translateX(-4.5rem) scale(0.6);
    }
    75% {
      transform: translateX(-7.5rem) scale(0.5);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @keyframes animate-to-0 {
    25% {
      transform: translateX(-1.5rem) scale(0.75);
    }
    50% {
      transform: translateX(-4.5rem) scale(0.6);
    }
    75% {
      transform: translateX(-7.5rem) scale(0.5);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @-webkit-keyframes animate-to-5 {
    25% {
      transform: translateX(1.5rem) scale(0.75);
    }
    50% {
      transform: translateX(4.5rem) scale(0.6);
    }
    75% {
      transform: translateX(7.5rem) scale(0.5);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
  @keyframes animate-to-5 {
    25% {
      transform: translateX(1.5rem) scale(0.75);
    }
    50% {
      transform: translateX(4.5rem) scale(0.6);
    }
    75% {
      transform: translateX(7.5rem) scale(0.5);
    }
    95% {
      transform: translateX(0rem) scale(1);
    }
  }
`;

const AnimatedLoader = (): JSX.Element => {
  return (
    <Flex flex="1" justifyContent="center" alignItems="center">
      <Loader>
        <Box width="100px" height="100">
          <ImageAutoHeight
            priority
            layout="fill"
            src="/logo-signet.svg"
            alt="Accred logo signet"
          />
        </Box>
        <Box position="relative" mt="25px" mb="15px">
          <div className="blob blob-0"></div>
          <div className="blob blob-1"></div>
          <div className="blob blob-2"></div>
          <div className="blob blob-3"></div>
          <div className="blob blob-4"></div>
          <div className="blob blob-5"></div>
        </Box>
      </Loader>
    </Flex>
  );
};

export default AnimatedLoader;
